.nav-bar {
  position: fixed;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:1rem;
  background-color: var(--light);
}
.nav-bar img {
  width: 80px;
}
.nav-menu {
  display: flex;
}
.nav-item {
  padding: 1rem;
}
.nav-item:hover {
  cursor: pointer;
}
.nav-item.active {
  color: var(--pink);
}

.hamburger {
  display: none;
  color: var(--dark);
}

@media screen and (max-width: 940px) {
  .nav-menu {
    position: fixed;
    left: -100%;
    top: 100px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--light);
    width: 100%;
    height: 100% !important;
    text-align: center;
    transition: 0.4s;
    z-index: 999;
    overflow-y:hidden;
  }
  .nav-menu.active {
    left: 0;
  }
  .nav-item {
    margin: 1rem;
    font-size: 1.4rem;
    justify-content: center;
  }

  .hamburger {
    display: flex;
  }

}
