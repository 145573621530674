@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,500,700&display=swap");

body {
  font-family: "Nunito";
}
/* .condiv class is for the right side content container*/
.condiv {
  padding: 100px;
  flex: var(--condiv-flex);
}
.even {
  background-color: var(--light);
}
.blue {
  background-color: var(--blue);
}
.blue h1, .blue p, .blue .skills li{
  color: var(--light);
}
.green {
  background-color: var(--green);
}
.green h1, .green p {
  color: var(--light);
}

.home {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.profilepic {
  border-radius: 50%;
  width: 40%;
  min-width: 250px;
  max-width: 400px;
  margin: 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.subtopic {
  margin: 10px 10px 30px 10px !important;
}
p {
  font-size: var(--fontsize-p);
}
h1,
h2,
h3,
h4,
p {
  color: var(--dark);
  margin: 10px;
}

.widecard {
  border-radius: 10px;
  border: 0px solid #9a9b9c;
  display: inline-grid;
  grid-template-areas: 
  'headerimg header headeryears'
  '. main.';
  grid-template-columns: 50px 5fr 1fr;
  grid-gap:10px;
  margin: 30px 0px 30px 0px;
  background-color: var(--light);
}
.widecard>img {
  grid-area:headerimg;
  width:100%;
  background-color: white;
}
.widecard>.headertext {
  grid-area:header;
}
.widecard>.maintext {
  grid-area:main;
}
.widecard>h4 {
  grid-area:headeryears;
}
.widecard:hover {
  background-color: var(--brown);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

#contactContainer {
  height: 250px;
  background-color: var(--dark);
}
#contactContainer .condiv {
  padding-bottom: 0px;
}
#contactContainer p, #contactContainer .sociali{
  color: var(--light);
}

.social {
display: flex;
flex-direction: row;
justify-content: center;
bottom: 10px;
}

.sociali {
display: inline-block;
margin: 20px;
font-size: 25px;
color: var(--light);
}
.sociali:hover {
color: var(--brown);
}


@media all and (max-width: 750px) {
  :root {
    --fontsize-p: 1rem;
  }
 
  .condiv
  {
    padding: 25px ;
  }

  .social
  {
    left:0;
    width: 100vw;
    display: flex;
    bottom: 20px;
  }
  .sociali
  {
    padding-right: 0px;
  }
}