.condivlist>ul,
.condivlist>li {
  list-style: none;
  margin: 0;
  padding: 0;
}
  
.condivlist>ul {
  width:90%;
  background: #fcfcfc;
  padding: 20px;
  border-radius: 25px;
  float:left;
}
  
.condivlist>li {
  background-color: var(--brown);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  max-width:783px;
}
  
.condivlist>li:last-child {
  margin-bottom: 0px;
}

.avatar {
  display: inline-grid;
  grid-template-areas: 
  'headerimg . .'
  'headerimg header headeryears'
  'headerimg main .'
  'headerimg . .';
  grid-template-columns: 50px 3fr 1fr;
  grid-template-rows: 15px;
  padding-top:0em;
  padding-bottom:0em;
  width: 100%;
  border-radius: 20px;
}
  
.avatar img {
  width: 50px;
  border-radius: 100%;
  margin-right: 15px;
  grid-area:headerimg;
}
  
.row {
  margin-top: 8px;
}
  
.avatarimg {
  width: 250px;
  height: 50px;
}
.avatar>.headertext {
  grid-area:header;
  text-align: justify;
  margin-bottom: 5px;
}
.avatar>.maintext {
  grid-area:main;
  text-align: justify;
  margin-top:5px;
}
.avatar>h4 {
  grid-area:headeryears;
}

@media screen and (max-width: 750px) {
  .condivlist {
    padding: 0;
    margin: 0;
  }
  .condivlist li {
    padding: 10px;
    max-height: 40vh;
    overflow: auto;
  }
  .avatar {
    display: inline-grid;
    grid-template-areas: 
    'headerimg . .'
    'headerimg header headeryears'
    'headerimg main .'
    'headerimg . .';
    grid-template-columns: 40px 2fr 1fr;
    grid-template-rows: 5px;
    padding-top:0em;
    padding-bottom:0em;
    width: 100%;
    border-radius: 20px;
  }
  .row {
    padding:0px;
      }
  .avatar img {
    height: 40px;
    width: 40px;
  }
  .avatar>h3 {
    font-size: 0.8rem;
  }
  .avatar>h4 {
    font-size:0.6rem;
  }
}