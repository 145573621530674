section {
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

section img,
section div {
	height: auto;
	width: 100%;
}

section img {
	width: 50%;
}

section div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1em;
	max-width:1000px;
}

.skills {
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.devicon {
	font-size: 3.5rem;
}
.skills>.item{
	flex-direction: column;
	color: var(--light);
	font-size: var(--fontsize-p);
	flex-basis: 33%;
	box-sizing: border-box;

}
.skills>.item>.icon{
	margin-right: 10px;
}
.home>.skills {
	flex-direction: column;
}
.home>.skills>.item{
	font-size: 20px;
}


@media screen and (max-width: 900px) {
	.home .skills {
		display: flex;
		align-items: flex-end;
		padding:0;
	}
	.home .skills .item {
		font-size: 1rem;
	}
	section div {
		padding: 0.5em;
	}

	section,
	section:nth-child(even) {
		flex-direction: column;
	}

	section img {
		height: auto;
		width: auto;
		max-width: 100vw;
	}
}